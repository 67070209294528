@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&family=Koulen&family=Krona+One&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    background-color: #f3f7f9;
    font-family: "Nunito Sans";
}

body.dark {
    background-color: #283e72;
}

a:visited {
    text-decoration: none;
}

a {
    color: inherit;
}

.wmde-markdown *,
.ck-content * {
    font-family: "Nunito Sans";
}

.ck-content {
    min-height: 300px;
}

.wmde-markdown.editable-text-field:hover,
.MuiTypography-root.editable-text-field:hover,
.editable-text-field:hover {
    background-color: rgba(80, 147, 235, 0.1);
    cursor: pointer;
}

.wmde-markdown p,
.wmde-markdown li,
.ck-content p,
.ck-content li {
    font-weight: 400;
    color: "#283E72";
}

.wmde-markdown h1,
.wmde-markdown h2,
.wmde-markdown h3,
.ck-content h1,
.ck-content h2,
.ck-content h3 {
    font-family: "Nunito Sans";
    color: #283e72;
    border-bottom: unset !important;
}

.wmde-markdown a,
.ck-content a {
    text-decoration: underline;
}

.MuiDataGrid-main .MuiDataGrid-cellCheckbox > .Mui-checked,
.MuiDataGrid-main .MuiDataGrid-columnHeaderTitleContainer > .Mui-checked {
    color: #1a82c5;
}

.funnel-container svg {
    overflow: visible;
}

.cc-banner {
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
}

:root {
    --swiper-theme-color: #ffffff !important;
}

body .swiper-button-prev {
    left: 0px;
}

body .swiper-button-next {
    right: 0px;
}

.fredoka {
    font-family: "Fredoka", sans-serif;
    font-optical-sizing: auto;
    font-weight: normal;
    font-style: normal;
    font-variation-settings: "wdth" 100;
}

.fredoka-bold {
    font-family: "Fredoka", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-variation-settings: "wdth" 100;
}

.koulen {
    font-family: "Koulen", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.krona {
    font-family: "Krona One", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.landing-button{
    @apply cursor-pointer transition transform hover:scale-[0.96];
}

.uklsl {
    width: 10% !important;
    height: 10% !important;
}
  
@media (max-width: 599px) {
    .uklsl {
        width: 20% !important;
        height: 20% !important;
    }
}

.ck-content * {
    all: revert;
}