/*animations*/
@keyframes floatAndExpand {
    0% {
        letter-spacing: 0.05em;
    }
    100% {
        letter-spacing: 0.1em; /* Wider letter spacing */
    }
}
@keyframes floatAndClose {
    0% {
        letter-spacing: 0.1em;
    }
    100% {
        letter-spacing: 0.05em; /* Wider letter spacing */
    }
}
@keyframes letterFloating {
    0% {
        transform: translate(0,0);
        rotate: none;

    }
    50% {
        transform: translate(-2px,-10px);
        rotate: 8deg;
    }
    100% {
        transform: translate(0,0);
        rotate: none;
    }
}
@keyframes letterFloating2 {
    0% {
        transform: translate(0,0);
        rotate: none;

    }
    50% {
        transform: translate(2px,-10px);
        rotate: -8deg;
    }
    100% {
        transform: translate(0,0);
        rotate: none;
    }
}

/*title*/
.animated-text span {
    display: inline-block;
}

.animated-text {
    display: inline-block;
    animation: floatAndExpand 1.5s ease-in-out 1 ;
    transition: letter-spacing 0.5s ease;
    letter-spacing: 0.1em;
}
.animated-text span:nth-child(2n) {
    animation: letterFloating 4s ease-in-out infinite;
}
.animated-text span:nth-child(odd) {
    animation: letterFloating2 4s ease-in-out infinite;
    animation-delay: 0.5s;
}
.animated-text span:nth-child(3n) {
    animation: letterFloating 4s ease-in-out infinite;
    animation-delay: 1.5s;
}
.animated-text span:nth-child(4n) {
    animation: letterFloating 4s ease-in-out infinite;
    animation-delay: 4s;
}

.scroll-stop {
    animation: floatAndClose 1s ease-out 1; /* Stop the animation */
    letter-spacing: 0.05em; /* Reset letter spacing */
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
/*shooting-star*/
@keyframes moveToTopRight {
    to {
        transform: translate(calc(70vw - 60px), -50vh);
        opacity: 0;
    }
}

@keyframes moveToTopRightMobile {
    to {
        transform: translate(calc(70vw - 60px), -20vh);
        opacity: 0;
    }
}

.shooting-star {
    opacity: 1;
    transition: transform 0.5s, opacity 0.5s;
}

.shooting-star.animate {
    animation: moveToTopRight 1s forwards;
}

/* Media query for mobile */
@media (max-width: 768px) {
    .shooting-star.animate {
        animation: moveToTopRightMobile 1s forwards;
    }
}
/*like space*/
.fade-in {
    opacity: 0;
    transform: translateY(50px); /* Move element slightly off-screen */
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.visible {
    opacity: 1;
    transform: translateY(0);
}
/*like space*/
@keyframes float {
    0%, 100% {
        transform: translateY(0);
        rotate:2deg;
    }
    50% {
        transform: translateY(-15px);
        rotate:-2deg;
    }
}
@keyframes slowFloat {
    0%, 100% {
        transform: translateY(0);
        scale: 1;
    }
    50% {
        transform: translateY(-15px);
        scale: 103%;
    }
}

@keyframes slideInOut {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    20% {
        transform: translateX(0);
        opacity: 1;
    }
    80% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateX(100%);
        opacity: 0;
    }
}

.floating {
    animation: float 3s ease-in-out infinite;
    transition: transform 3s ease-out, rotate 3s ease-out;
}

.slow-floating {
    animation: slowFloat 6s ease-in-out infinite;
    transition: transform 6s ease-out, rotate 6s ease-out;
}

.transition-opacity {
    transition: opacity 0.5s ease-in-out; /* Adjust duration as needed */
}






